import { Fragment, useCallback, useEffect } from "react";

import { Aggregator, EarlyAccessFeature, Feature, Metric, Renderer, TimeInterval } from "@doitintl/cmp-models";
import { Checkbox, MenuItem, TextField, Tooltip } from "@mui/material";
import ListItem from "@mui/material/ListItem";

import { reportText } from "../../../../assets/texts";
import { useFeatureFlag } from "../../../../Components/hooks/useFeatureFlag";
import { useAuthContext } from "../../../../Context/AuthContext";
import { useIsFeatureEntitled } from "../../../../Context/TierProvider";
import { getCurrencySymbol } from "../../../../utils/common";
import { useReportConfig, useReportDimensionsContext } from "../../Context";
import { useStyles } from "../../ReportStyles";
import {
  advancedOptionsRenderValue,
  advancedOptionsTitle,
  AggregatorOptions,
  CurrencyOptions,
  FeatureOptions,
  getAvailableAggregators,
  isComparative,
  isTimeSeriesReport,
  IsValidAggregator,
  MenuProps,
} from "../../utilities";
import { IncludeCreditsSwitch } from "./IncludeCreditsSwitch";
import ReportsAccordion from "./ReportsAccordion";

export type AdvancedOptionsProps = {
  handleAggregatorSelect: (aggregator: Aggregator) => void;
  queryRunning: boolean;
};

const AdvancedOptions = ({ handleAggregatorSelect, queryRunning }: AdvancedOptionsProps) => {
  const { isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const isEntitledAnalyticsAdvanced = useIsFeatureEntitled("analytics:advanced");
  const classes = useStyles();
  const isCountAggregatorVisible = useFeatureFlag(EarlyAccessFeature.COUNT_AGGREGATION);
  const { dimensions = [] } = useReportDimensionsContext();
  const { reportConfig, dispatchReportConfig } = useReportConfig();
  const { currency, aggregator, renderer, features, timeInterval, metric, comparative } = reportConfig;

  const onChange = useCallback(
    (event) => {
      dispatchReportConfig({ payload: { features: event.target.value } });
    },
    [dispatchReportConfig]
  );

  const onChangeAggregation = useCallback(
    (event) => {
      handleAggregatorSelect(event.target.value as Aggregator);
    },
    [handleAggregatorSelect]
  );

  const handleChangeCurrency = useCallback(
    (event) => {
      dispatchReportConfig({ payload: { currency: event.target.value } });
    },
    [dispatchReportConfig]
  );

  useEffect(() => {
    if (aggregator === Aggregator.TOTAL_OVER_TOTAL && (metric === Metric.EXTENDED || metric === Metric.CALCULATED)) {
      dispatchReportConfig({ payload: { aggregator: Aggregator.TOTAL } });
    }
  }, [metric, aggregator, dispatchReportConfig]);

  return (
    <ReportsAccordion title={reportText.ADVANCED_OPTIONS_SECTION_LABEL} numOfItems={3}>
      <Fragment>
        {(isDoitEmployee || isEntitledAnalyticsAdvanced) && (
          <Tooltip title={advancedOptionsTitle(dimensions, timeInterval, features)} placement="bottom-start">
            <TextField
              label="Advanced Analysis"
              data-cy="advanced-analysis"
              value={features}
              disabled={!isTimeSeriesReport(dimensions, timeInterval)}
              onChange={onChange}
              variant="outlined"
              margin="dense"
              select
              fullWidth
              slotProps={{
                select: {
                  multiple: true,
                  displayEmpty: true,
                  renderValue: (values) => advancedOptionsRenderValue(dimensions, values as Feature[], timeInterval),
                  classes: {
                    select: classes.selectOutlined,
                  },
                  MenuProps: {
                    ...MenuProps,
                    PopoverClasses: {
                      root: classes.customMenu,
                    },
                  },
                },

                inputLabel: { shrink: true, margin: "dense" },
              }}
            >
              {FeatureOptions.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  disabled={
                    (timeInterval === TimeInterval.YEAR || aggregator !== Aggregator.TOTAL) &&
                    option.value === Feature.FORECAST
                  }
                  dense
                  data-cy={option.value}
                >
                  <Checkbox size="small" checked={features?.includes(option.value)} />
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Tooltip>
        )}
      </Fragment>
      <TextField
        label="Aggregation"
        data-cy="aggregation"
        value={aggregator}
        onChange={onChangeAggregation}
        variant="outlined"
        disabled={renderer === Renderer.TREEMAP || !!(comparative && isComparative(comparative))}
        margin="dense"
        select
        fullWidth
        slotProps={{
          select: {
            classes: {
              select: classes.selectOutlined,
            },
            MenuProps,
          },
        }}
      >
        {getAvailableAggregators(AggregatorOptions, aggregator, isCountAggregatorVisible, metric).map(
          (aggregatorOption) => (
            <MenuItem
              key={aggregatorOption.value}
              value={aggregatorOption.value}
              disabled={!IsValidAggregator(aggregatorOption.value, metric)}
              dense
              data-cy={
                typeof aggregatorOption.label === "function" ? aggregatorOption.label(metric) : aggregatorOption.label
              }
            >
              {typeof aggregatorOption.label === "function" ? aggregatorOption.label(metric) : aggregatorOption.label}
            </MenuItem>
          )
        )}
      </TextField>
      <TextField
        size="small"
        label={reportText.CURRENCY}
        data-cy={reportText.CURRENCY}
        value={currency}
        disabled={false}
        onChange={handleChangeCurrency}
        variant="outlined"
        margin="dense"
        select
        fullWidth
        sx={{
          "& .MuiInputBase-input": {
            textOverflow: "clip", // Removes ellipsis and overflow effect
          },
        }}
        slotProps={{
          select: {
            classes: {
              select: classes.selectOutlined,
            },
            MenuProps: {
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "center",
              },
            },
          },
        }}
      >
        {CurrencyOptions.map((option) => (
          <MenuItem key={option} value={option} dense data-cy={option}>
            {option}
            <ListItem
              className={classes.currencySymbol}
              secondaryAction={getCurrencySymbol(option)}
              sx={{
                display: "inline-flex",
                "& .MuiListItemSecondaryAction-root": {
                  height: "100%",
                  right: "2rem",
                },
              }}
            />
          </MenuItem>
        ))}
      </TextField>
      <IncludeCreditsSwitch disabled={queryRunning} />
    </ReportsAccordion>
  );
};
export default AdvancedOptions;
