import { type Dispatch } from "react";

import { useTranslation } from "react-i18next";
import { type RouteComponentProps } from "react-router-dom";
import { Button } from "@mui/material";
import * as Sentry from "@sentry/react";
import { type AuthProvider, OAuthProvider, SAMLAuthProvider, type UserCredential } from "firebase/auth";
import { type History, type LocationState } from "history";
import { type TFunction } from "i18next";
import { bool as YupBool, object as YupObject, string as YupString } from "yup";

import { loginTexts } from "../../assets/texts";
import { type SharedSnackbarContextProps } from "../../Components/SharedSnackbar/SharedSnackbar.context";
import { routes } from "../../constants/navigation";
import { consoleErrorWithSentry } from "../../utils";
import mixpanel from "../../utils/mixpanel";
import { SegmentEvents, segmentIdentify, segmentTrack } from "../../utils/segment";
import { type UtmParams } from "../../utils/utmParams/types";
import {
  type LoginHookAction,
  LoginHookActionType,
  type LoginType,
  type SignUpMode,
  type SupportedAuthProviders,
} from "./types";

export const paths = {
  login: routes.signin,
  loginSuccess: `${routes.signin}/success`,
  loginError: `${routes.signin}/error`,
  completeSignup: "/complete-signup",
  signup: "/signup",
  signupError: "/signup/error",
  loginImpersonate: `${routes.signin}/impersonate`,
  authReadme: "/auth/readme",
  authAnnounceKit: "/auth/announcekit",
  authGoogle: "/auth/google",
  authMicrosoft: "/auth/microsoft",
  authConfig: "/auth/config",
  authTrial: "/auth/trial/validation",
  authEmail: "/auth/email/handler",
  authRegisterWithEmail: "/auth/email/register",
};

export enum AuthErrors {
  userNotFound = "auth/user-not-found",
  wrongPassword = "auth/wrong-password",
  tooManyRequests = "auth/too-many-requests",
  invalidEmail = "auth/invalid-email",
}

export const emailLocalStorageKey = "emailForSignIn";

export const postSignIn = async ({
  userCred,
  history,
}: {
  userCred: UserCredential;
  history: RouteComponentProps["history"];
}) => {
  try {
    const email = userCred?.user?.email ?? undefined;
    Sentry.setUser({
      email,
      id: userCred?.user?.uid,
      displayName: userCred?.user?.displayName,
      emailVerified: userCred?.user?.emailVerified,
    });
    if (userCred?.user?.emailVerified) {
      history.push(paths.loginSuccess);
    }
  } catch (error: any) {
    let message: string;
    if (error.code === AuthErrors.wrongPassword) {
      message = loginTexts.SIGNIN_FAILED_INCORRECT_CREDS;
    } else {
      message = error.message;
    }

    Sentry.setUser(null);

    if (!error.code?.startsWith("auth/")) {
      consoleErrorWithSentry(error);
    }
    throw new Error(message);
  }
};

export const getSsoInstance: (providerId: string, providerType: string | undefined) => AuthProvider = (
  providerId,
  providerType
) => {
  let type = providerType;
  if (!type) {
    const providerItems = providerId.split(".");
    if (providerItems.length > 1) {
      type = providerItems[0];
    }
  }
  switch (type) {
    case "saml": {
      return new SAMLAuthProvider(providerId);
    }
    case "oidc": {
      return new OAuthProvider(providerId);
    }
    default: {
      throw new Error("No SSO Provider found");
    }
  }
};

export const useEmailValidation = () => {
  const { t } = useTranslation("login");
  return YupString().email(t("signup.email.invalid")).required(t("signup.email.required"));
};
export const useWhiteListedEmailValidation = () => {
  const emailValidation = useEmailValidation();
  return emailValidation.test(
    "isBusinessEmail",
    "We only accept business email addresses",
    (value) => !/gmail.com|hotmail.com|yahoo.com/.test(value || "")
  );
};

export const usePasswordValidation = () => {
  const { t } = useTranslation("login");

  return YupString()
    .required(t("signup.password.required"))
    .matches(/^(?=.*[a-zA-Z])(?=.*\d)/, loginTexts.PASSWORD_VALIDATION_MESSAGE)
    .min(6, loginTexts.PASSWORD_VALIDATION_LENGTH);
};

export const useEmailSchema = () => {
  const emailValidation = useEmailValidation();
  return YupObject().shape({
    email: emailValidation,
  });
};

export const useEmailAndPasswordSchema = () => {
  const passwordValidation = usePasswordValidation();
  const emailValidation = useEmailValidation();
  return YupObject().shape({
    email: emailValidation,
    password: passwordValidation,
  });
};

export const useConsoleSignupSchema = () => {
  const { t } = useTranslation("login");
  const emailValidation = useEmailValidation();
  return YupObject().shape({
    email: emailValidation,
    firstName: YupString().required(t("signup.firstName.required")),
    lastName: YupString().required(t("signup.lastName.required")),
    policyAgreement: YupBool().oneOf([true], t("signup.mustAccept")),
  });
};

export const trackSignup = ({ uid, email }: { uid?: string; email?: string }) => {
  if (uid && email) {
    segmentIdentify(uid, {
      email,
      trialUser: true,
    });
  }
  segmentTrack(SegmentEvents.SignUp, null);
};

export const trackRejectedSignup = ({ rejectedEmail }: { rejectedEmail: string }) => {
  segmentTrack(SegmentEvents.RejectedRegistration, { email: rejectedEmail });
};

export const trackConsoleAuthSignup = (provider: SupportedAuthProviders) => {
  mixpanel.track(`signup.${provider.toLowerCase()}-click`);
};

export const trackAuthByProvider = (provider: SupportedAuthProviders) => {
  mixpanel.track(`login.with-${provider.toLowerCase()}`);
};

export const trackConsoleCreateAccountSignup = () => {
  mixpanel.track("signup.create_account-click");
};

export const isSsoSignInAttempt = (providerId: string | undefined): boolean => {
  if (providerId) {
    const providerType = providerId.split(".");
    if (providerType.length > 1) {
      return providerType[0] === "saml" || providerType[0] === "oidc";
    }
  }
  return false;
};

export const handleEmailExistsRedirect = (
  dispatch: Dispatch<LoginHookAction>,
  email: string,
  history: History<LocationState>
) => {
  dispatch({ type: LoginHookActionType.setEmail, email });
  dispatch({ type: LoginHookActionType.setFormType, formType: "checkEmail" });
  history.push(routes.signin);
};

/**
 * https://app.storyblok.com/#!/me/spaces/138317/stories/0/0/index/90761090?page=1
 */
export const dynamicContentPathByLoginType: Record<LoginType, string> = {
  login: "login/content",
  signup: "login/signup-content",
  customerSignup: "login/signup-content",
  completeSignup: "login/signup-content",
};

export const showUserNotFoundSnackbar = ({
  showSnackbar,
  dispatch,
  history,
}: {
  showSnackbar: SharedSnackbarContextProps;
  dispatch: Dispatch<LoginHookAction>;
  history: History<LocationState>;
}) => {
  showSnackbar.onOpen({
    message: loginTexts.USER_NOT_FOUND,
    autoHideDuration: 10000,
    variant: "error",
    withClose: true,
    action: [
      <Button
        key="link"
        aria-label="Create account"
        color="inherit"
        variant="outlined"
        onClick={() => {
          dispatch({ type: LoginHookActionType.setLoginType, loginType: "signup", formType: "signup" });
          history.push("/signup");
        }}
      >
        Create account
      </Button>,
    ],
  });
};

export function getAuthProvidersButtonText(
  {
    loginType,
    provider,
  }: {
    loginType: LoginType;
    provider: SupportedAuthProviders;
  },
  t: TFunction<"login">
) {
  switch (provider) {
    case "Google": {
      return loginType === "login" ? t("google.signin") : t("google.signup");
    }
    case "Microsoft": {
      return loginType === "login" ? t("microsoft.signin") : t("microsoft.signup");
    }
  }
}

export function constructAuthProviderRoute({
  provider,
  loginType,
  utmParams,
  redirect,
  isCompleteSignup,
}: {
  provider: SupportedAuthProviders;
  loginType: LoginType;
  utmParams?: UtmParams | null;
  redirect?: string | null;
  isCompleteSignup?: boolean;
}) {
  let providerOnPath: string;
  switch (provider) {
    case "Google":
      providerOnPath = "google";
      break;
    case "Microsoft":
      providerOnPath = "microsoft";
      break;
    default: {
      throw new Error("invalid provider");
    }
  }

  const url = new URL(`/auth/${providerOnPath}`, window.location.origin);

  if (loginType === "signup") {
    url.searchParams.set("mode", "customerSignup");
  }

  for (const [key, value] of Object.entries(utmParams ?? {})) {
    if (value) {
      url.searchParams.set(key, value);
    }
  }

  if (redirect) {
    url.searchParams.set("redirect", redirect);
  }
  if (isCompleteSignup) {
    url.searchParams.set("isCompleteSignup", "true");
  }

  return `${url.pathname}${url.search}`;
}

export const emailVerificationStatusParam = "emailVerificationStatus";

export enum EmailVerificationParamStatus {
  Success = "success",
  Failure = "failure",
  ExpiredOobCode = "expired",
  InvalidOobCode = "invalid",
}

export const emailVerificationSuuccessMessage = "Your email has been verified. You can now sign-in";

export function getErrorMessageFromEmailVerificationStatus(status: string) {
  switch (status) {
    case EmailVerificationParamStatus.ExpiredOobCode:
      return "Email verification link expired. Please request a new one";
    case EmailVerificationParamStatus.InvalidOobCode:
      return "Email verification link is invalid. Please request a new one";
    default:
      return "Email verification failed. Please try again with a new link";
  }
}

export const completeSignupErrorQueryParam = "regError";
export const completeSignupEmailQueryParam = "email";
export const completeSignupProviderQueryParam = "provider";
export const completeSignupCheckInboxQueryParam = "checkInbox";
export const completeSignupModeQueryParam = "mode";

export type CompleteSignupProviderType = "google" | "microsoft" | "email";
const completeSignupProviders: CompleteSignupProviderType[] = ["google", "microsoft", "email"];
export const isCompleteSignupProvider = (provider: string): provider is CompleteSignupProviderType =>
  completeSignupProviders.includes(provider as CompleteSignupProviderType);

export function getLoginTypeFromMode(mode: SignUpMode | undefined) {
  if (mode === "customerSignup") {
    return "signup";
  }
  return undefined;
}

export enum CompleteSignupErrorParam {
  MissingToken = "MissingToken",
  Expired = "Expired",
  UsedAlready = "UsedAlready",
  InvitationUsedAlready = "InvitationUsedAlready",
  Invalid = "Invalid",
  RateLimitExceeded = "RateLimitExceeded",
  AccountWithDifferEmailExists = "AccountWithDifferEmailExists",
  UseTheSameEmail = "UseTheSameEmail",
}

export function getErrorMessageFromCompleteSignupErrorParam(status: string) {
  switch (status) {
    case CompleteSignupErrorParam.Expired:
      return "Signup link expired. Please request a new one";
    case CompleteSignupErrorParam.UsedAlready:
    case CompleteSignupErrorParam.InvitationUsedAlready:
      return "You probably signed up already. Try to sign-in or retry to signup";
    case CompleteSignupErrorParam.RateLimitExceeded:
      return "Too many attempts. Please try again later";
    case CompleteSignupErrorParam.AccountWithDifferEmailExists:
      return "Account is already registered with a different email";
    case CompleteSignupErrorParam.UseTheSameEmail:
      return "Please use the same email you've used to sign up";
    case CompleteSignupErrorParam.Invalid:
    case CompleteSignupErrorParam.MissingToken:
      return "Please click the button in your email and try again";
    default:
      return "Signup failed. Please try again with a new link";
  }
}
