import { useCallback, useEffect, useState } from "react";

import {
  type AmazonWebServicesAssetModelProperties,
  AppModel,
  type MasterPayerAccountsModel,
  type SupportModel,
} from "@doitintl/cmp-models";
import { getCollection, useCollectionData } from "@doitintl/models-firestore";

import { useCustomerContext } from "../../../Context/CustomerContext";
import { type Assets } from "../../../types";

type MpaDetails = { masterPayerAccountId: string; supportModel: SupportModel };

export const useGetCustomerMPAs = (
  partnerLed: boolean
): {
  assetsOwnedByMPAs: Assets[number];
  customerMPAs?: MasterPayerAccountsModel[];
  getAwsAssetMPAByAccountId: (accountId: string) => MpaDetails | undefined;
} => {
  const { customer, assets } = useCustomerContext();

  const [assetsOwnedByMPAs, setAssetsOwnedByMPAs] = useState<Assets[number]>([]);

  const awsPrefix = "amazon-web-services-";

  const query = getCollection(AppModel)
    .doc("master-payer-accounts")
    .collection("mpaAccounts")
    .where("customerId", "==", customer.id);
  // if we only want partner-led MPAs? Or MPAs whose support model is not null/empty...
  if (partnerLed) {
    query.where("support.model", "==", "partner-led");
  } else {
    query.where("support.model", "!=", null);
  }

  const [customerMPAs, mpasLoading] = useCollectionData(query);

  useEffect(() => {
    if (!customerMPAs || !assets || mpasLoading) {
      setAssetsOwnedByMPAs([]);
      return;
    }

    // filtering assets to only include those owned by the customerMPAs set
    const mpaAccountIds = customerMPAs.map((mpa) => mpa.accountNumber);
    const assetsList = Object.values(assets)
      .reduce((acc, assetsGroup) => [...acc, ...assetsGroup], [])
      .filter((asset) => {
        const assetData = asset.data;
        if (assetData.type !== "amazon-web-services") {
          return false;
        }

        const properties: AmazonWebServicesAssetModelProperties =
          assetData.properties as AmazonWebServicesAssetModelProperties;
        return (
          !!properties.organization?.payerAccount?.id &&
          mpaAccountIds.includes(asset.data.properties.organization.payerAccount.id as string)
        );
      });
    setAssetsOwnedByMPAs(assetsList);
  }, [assets, customerMPAs, mpasLoading]);

  // check if the asset being queried belongs to either a partner-led MPA, or an MPA whose support model is not null
  // according to the query/parameter above
  const getAwsAssetMPAByAccountId = useCallback(
    (accountId: string): MpaDetails | undefined => {
      if (!customerMPAs || customerMPAs.length === 0 || mpasLoading) {
        return undefined;
      }

      const assetsList = Object.values(assets)
        .reduce((acc, assetsGroup) => [...acc, ...assetsGroup], [])
        .filter((asset) => asset.id.replace(awsPrefix, "") === accountId);

      if (assetsList.length === 0) {
        return undefined;
      }

      const mpaProperties: AmazonWebServicesAssetModelProperties = assetsList[0]?.data
        ?.properties as AmazonWebServicesAssetModelProperties;
      const masterPayerAccountId: string = mpaProperties.organization?.payerAccount.id ?? "";

      const matchingMpas: MpaDetails[] = [];
      customerMPAs.reduce((acc, mpa) => {
        if (mpa.accountNumber === masterPayerAccountId) {
          acc.push({ masterPayerAccountId, supportModel: mpa.support.model });
        }
        return acc;
      }, matchingMpas);
      return matchingMpas.length > 0 ? matchingMpas[0] : undefined;
    },
    [assets, customerMPAs, mpasLoading]
  );

  return { assetsOwnedByMPAs, customerMPAs, getAwsAssetMPAByAccountId };
};
