import {
  type BudgetAlert,
  type BudgetConfig,
  type CurrencyCode,
  TimeInterval,
  TimeSettingsMode,
} from "@doitintl/cmp-models";
import { type Timestamp } from "@doitintl/models-types";
import { darken, lighten } from "@mui/material";
import { type Theme } from "@mui/material/styles";
import { type CallbackDataParams } from "echarts/types/dist/shared";
import { type EChartsInstance } from "echarts-for-react";
import capitalize from "lodash/capitalize";
import { DateTime } from "luxon";

import { budgetTxt } from "../../../assets/texts/CloudAnalytics/budget";
import { generateHighchartList3 } from "../../../cmpBaseColors";
import { formatValueWithCurrency } from "../../../Components/hooks/useFormatter";
import { ThemeModes } from "../../../muiThemeTypes";
import { type MarkPointData } from "../renderers/useChartsSeries";
import { BudgetConfigurations, BudgetDynamicConfigurations, BudgetTypes, type TimeRangeOption } from "../utilities";
import type { Budget } from "../../../types";

export const fullPreviewAmountByInterval = {
  [TimeInterval.DAY]: 24,
  [TimeInterval.WEEK]: 7,
  [TimeInterval.MONTH]: daysInCurrentMonth(),
  [TimeInterval.QUARTER]: weeksInCurrentQuarter(),
  [TimeInterval.YEAR]: 12,
};

export const previewAmountByInterval = {
  [TimeInterval.DAY]: hoursElapsedSinceStartOfDay(),
  [TimeInterval.WEEK]: daysElapsedSinceStartOfWeek(),
  [TimeInterval.MONTH]: daysElapsedSinceStartOfMonth(),
  [TimeInterval.QUARTER]: weeksElapsedSinceStartOfQuarter(),
  [TimeInterval.YEAR]: monthsElapsedSinceStartOfYear(),
};

const getRecurringTimeUnitAmount = (timeInterval: TimeInterval) => {
  switch (timeInterval) {
    case TimeInterval.DAY:
      return 30;
    case TimeInterval.WEEK:
      return 16;
    case TimeInterval.MONTH:
      return 6;
    case TimeInterval.QUARTER:
      return 4;
    case TimeInterval.YEAR:
      return 2;
    default:
      return 1;
  }
};

export const getTimeRangeOption = (
  budgetType: BudgetTypes,
  timeInterval: TimeInterval,
  startPeriod: DateTime,
  endPeriod: DateTime
): TimeRangeOption => {
  const amount = budgetType === BudgetTypes.RECURRING ? getRecurringTimeUnitAmount(timeInterval) : undefined;

  const range =
    budgetType === BudgetTypes.FIXED
      ? {
          start: startPeriod,
          end: endPeriod,
        }
      : undefined;

  const mode = budgetType === BudgetTypes.FIXED ? TimeSettingsMode.Fixed : TimeSettingsMode.Last;

  const includeCurrent = budgetType === BudgetTypes.RECURRING;

  return {
    mode,
    time: timeInterval,
    amount,
    range,
    includeCurrent,
  };
};

export const getFixedTimeInterval = (startPeriod: DateTime, endPeriod: DateTime): TimeInterval => {
  const diff = endPeriod.diff(startPeriod, "months").months;
  if (diff >= 12) {
    return TimeInterval.YEAR;
  } else if (diff >= 6) {
    return TimeInterval.QUARTER;
  } else if (diff >= 2) {
    return TimeInterval.MONTH;
  } else if (diff >= 1) {
    return TimeInterval.WEEK;
  }
  return TimeInterval.DAY;
};

type budgetState = {
  shouldRefreshData: boolean;
  shouldRefreshPreview: boolean;
  refreshPreview: boolean;
};

type budgetStateAction = {
  type:
    | "shouldRefreshData"
    | "startedRefreshPreview"
    | "finishedRefreshPreview"
    | "shouldRefreshPreview"
    | "startedRefreshData";
  payload?: boolean;
};

export const budgetStateReducer = (state: budgetState, action: budgetStateAction): budgetState => {
  switch (action.type) {
    case "shouldRefreshPreview":
      return { ...state, shouldRefreshPreview: true };
    case "startedRefreshPreview":
      return { ...state, refreshPreview: true, shouldRefreshPreview: false };
    case "finishedRefreshPreview":
      return { ...state, refreshPreview: action.payload ?? false };
    case "shouldRefreshData":
      return { ...state, shouldRefreshData: true };
    case "startedRefreshData":
      return { ...state, shouldRefreshData: false };
  }
};

export function weeksInCurrentQuarter() {
  const now = new Date();
  const quarterStartMonth = Math.floor(now.getMonth() / 3) * 3;
  const quarterStart: any = new Date(now.getFullYear(), quarterStartMonth, 1);
  const quarterEnd: any = new Date(now.getFullYear(), quarterStartMonth + 3, 0);
  const weekMilliseconds = 7 * 24 * 60 * 60 * 1000;
  return Math.ceil((quarterEnd - quarterStart + 1) / weekMilliseconds);
}

export function getStartAndEndOfPreviousDayUTC() {
  const previousDay = DateTime.utc().minus({ days: 1 });
  const startOfDay = previousDay.startOf("day").toUTC();
  const endOfDay = previousDay.endOf("day").toUTC();

  return { start: startOfDay, end: endOfDay };
}

export function getStartAndEndOfWeekUTC() {
  const now = DateTime.utc();
  const startOfWeek = now.startOf("week");
  const endOfWeek = startOfWeek.plus({ days: 6 }).endOf("day");

  return { start: startOfWeek, end: endOfWeek };
}

export function daysInCurrentMonth() {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1;

  return new Date(year, month, 0).getDate();
}

export function makeDataCumulative(
  data: Array<{ y: number; [key: string]: any }>,
  startPoint = 0
): Array<{ y: number; [key: string]: any }> {
  let cumulativeSum = startPoint;
  return (
    data?.map((point) => {
      cumulativeSum += point.y;
      return {
        ...point,
        y: cumulativeSum,
      };
    }) || []
  );
}

export const formatTimeIntervalPhrase = (interval: TimeInterval) =>
  interval === TimeInterval.DAY ? "daily" : `${interval}ly`;

export const getFrequencyPhrase = (interval: TimeInterval, frequency: string, isFixedBudget: boolean) => {
  const intervalString = formatTimeIntervalPhrase(interval);
  return !isFixedBudget ? `${capitalize(intervalString)} ${frequency}` : frequency;
};

export const getNearestValue = (arr: number[] = [], val: number): number =>
  arr.reduce((p, n) => (Math.abs(p) > Math.abs(n - val) ? n - val : p), Infinity) + val;

export const buildRange = (
  startPeriod: DateTime,
  endPeriod: DateTime,
  timeInterval: TimeInterval,
  type: BudgetTypes
): DateTime[] => {
  const intervals = {
    [TimeInterval.DAY]: { hours: 1 },
    [TimeInterval.WEEK]: { days: 1 },
    [TimeInterval.MONTH]: { days: 1 },
    [TimeInterval.QUARTER]: { weeks: 1 },
    [TimeInterval.YEAR]: { months: 1 },
  };

  const startPeriodUTC = startPeriod.toUTC();
  const endPeriodUTC = endPeriod.toUTC();

  const range: DateTime[] = [];
  let current: DateTime = startPeriodUTC;

  let intervalToUse = intervals[timeInterval];

  if (type === BudgetTypes.FIXED && timeInterval === TimeInterval.MONTH) {
    const diffInMonths = endPeriodUTC.diff(startPeriodUTC, "months").months;

    if (diffInMonths >= 2) {
      intervalToUse = { months: 1 };
    }
  }

  while (current <= endPeriodUTC) {
    range.push(current);
    if (!intervalToUse) {
      throw new Error("Invalid time interval");
    }
    current = current.plus(intervalToUse).toUTC();
  }

  return range;
};

export const getFixedBudgetTimeRange = (budget: Budget, timeInterval: TimeInterval): DateTime[] => {
  if (!budget?.data?.config?.startPeriod || !budget?.data?.config?.endPeriod) {
    return [];
  }

  const fixedStartPeriod = DateTime.fromJSDate(budget.data.config.startPeriod.toDate());
  const fixedEndPeriod = DateTime.fromJSDate(budget.data.config.endPeriod.toDate());

  return buildRange(fixedStartPeriod, fixedEndPeriod, timeInterval, BudgetTypes.FIXED);
};

export const getFilteredDatesUpToToday = (timeRange: DateTime[]): number => {
  const todayUtc = DateTime.utc().startOf("day");

  return timeRange.filter((date) => date <= todayUtc).length;
};

export const transformTimestampsToCategories = (timestamps: DateTime[], timeInterval: TimeInterval): string[] => {
  const intervals = {
    [TimeInterval.DAY]: { format: "yyyy-MM-dd HH:mm" },
    [TimeInterval.WEEK]: { format: "yyyy-MM-dd" },
    [TimeInterval.MONTH]: { format: "yyyy-MM-dd" },
    [TimeInterval.QUARTER]: { format: "yyyy-MM-dd" },
    [TimeInterval.YEAR]: { format: "yyyy-MM" },
  };

  const interval = intervals[timeInterval];
  if (!interval) {
    throw new Error("Invalid time interval");
  }

  return timestamps.map((timestamp) => timestamp.toFormat(interval.format));
};

export const fillNullValues = (chartData: number[], fullRangeData: string[], fromStart: boolean = false): number[] => {
  if (chartData.length === 0 && fullRangeData.length === 0) {
    return [];
  }

  const nullValuesArray = Array(fullRangeData.length - chartData.length).fill(null);
  const withNullValuesArray = fromStart ? nullValuesArray.concat(chartData) : chartData.concat(nullValuesArray);
  return withNullValuesArray;
};

export const getAlertTriggers = (
  budgetAmountToDateData: Array<{ x?: DateTime | null; y?: number | null }>,
  alerts: BudgetAlert[]
): MarkPointData[] => {
  const triggers = alerts
    ?.filter((alert) => alert.percentage !== 100)
    .map((alert) => {
      const budgetAmountForTrigger = getNearestValue(
        budgetAmountToDateData
          .map((budgetAmountToDateItem) => budgetAmountToDateItem.y)
          .filter((y) => typeof y === "number"),
        alert.amount ?? 0
      );
      const budgetDateIndex = budgetAmountToDateData?.findIndex(
        (budgetAmountToDateItem) => budgetAmountToDateItem.y === budgetAmountForTrigger
      );

      if (budgetAmountToDateData?.length - 1 === budgetDateIndex) {
        return null;
      }

      return {
        coord: [budgetDateIndex, budgetAmountForTrigger],
        value: budgetTxt.THRESHOLD_TRIGGER,
      };
    })
    .filter((trigger) => Boolean(trigger)) as MarkPointData[];

  return triggers;
};

export const getBudgetChartTooltip = (
  params: CallbackDataParams[],
  theme: Theme,
  valueFormatter: (value: number, isCurrency: boolean) => string
): string => {
  const presentPoints = params.map((i) => i.seriesName);
  const actual = params.find((i) => i.seriesName === budgetTxt.ACTUAL);
  const forecast = params.find((i) => i.seriesName === budgetTxt.FORECAST);
  const budget = params.find((i) => i.seriesName === budgetTxt.BUDGET_AMOUNT_TO_DATE);

  const isLightMode = theme.palette.mode === ThemeModes.LIGHT;
  const budgetAmountToDateColor = generateHighchartList3(isLightMode ? "light" : "dark")[4];
  const actualColor = generateHighchartList3(isLightMode ? "light" : "dark")[0];
  const forecastColor = isLightMode
    ? lighten(theme.palette.text.disabled, 0.2)
    : darken(theme.palette.text.disabled, 0.9);

  const showForecast = !presentPoints.includes(budgetTxt.ACTUAL);
  const currentVariance = showForecast
    ? Number(params[0]?.value ?? 0) - Number(params[1]?.value ?? 0)
    : Number(params[2]?.value ?? 0) - Number(params[0]?.value ?? 0);

  const firstLineColor = showForecast ? forecastColor : actualColor;
  const firstPointName = showForecast ? budgetTxt.FORECASTED : budgetTxt.ACTUALS;
  const firstPointValue = showForecast ? Number(forecast?.value) || 0 : Number(actual?.value) || 0;

  return `
    <span style="color:${firstLineColor};">
      <b> ${firstPointName}:</b>
    </span>
    <span style="color:rgba(0, 0, 0, 0.60)">${valueFormatter(Number(firstPointValue), false)}</span><br/>
    <span style="color:${budgetAmountToDateColor}">
      <b> Budget amount to date:</b>
    </span>
    <span style="color:rgba(0, 0, 0, 0.60)">${valueFormatter(Number(budget?.value) || 0, false)}</span><br/>
    <span><b> Variance:</b></span>
    <span style="color:rgba(0, 0, 0, 0.60)">${valueFormatter(Number(currentVariance) || 0, false)}</span>
  `;
};

export const getYExtendedMax = (instance: EChartsInstance): number => {
  const yAxisModel = instance.getModel().getComponent("yAxis", 0);
  const scale = yAxisModel.axis.scale;
  const maxYValue = Number(scale.getExtent()[1]);
  const yInterval = Number(scale.getInterval());
  return maxYValue + yInterval;
};

export const populateActualsYValues = (
  data: Array<{ x: DateTime; y: number }>,
  actual: number
): Array<{ x: DateTime; y: number }> => {
  const periods = data.length;
  const period = periods - 1;

  return data.map((point, index) => ({
    ...point,
    y: (actual / periods) * (index + 1) + period - 1,
  }));
};

export const populateForecastedYValues = (
  data: Array<{ x: DateTime; y: number }>,
  forecasted: number,
  startPoint: number
): Array<{ x: DateTime; y: number }> => {
  const periods = data.length;
  let cumulativeY = startPoint;
  return data.map((point) => {
    cumulativeY += forecasted / periods;
    return {
      ...point,
      y: cumulativeY,
    };
  });
};

export function getFirstDayOfISOWeek(year, weekNumber) {
  const jan4 = new Date(Date.UTC(year, 0, 4));
  const dayOfWeek = jan4.getUTCDay() || 7;
  const firstMonday = new Date(jan4);
  firstMonday.setUTCDate(jan4.getUTCDate() + 1 - dayOfWeek);

  const startOfWeek = new Date(firstMonday);
  startOfWeek.setUTCDate(firstMonday.getUTCDate() + (weekNumber - 1) * 7);

  return startOfWeek;
}

export function convertToUTCTimestamp(dateString) {
  const weekYearWithMonthDayMatch = dateString.match(/^(\d{4})-W(\d{2}) \((\w{3}) (\d{2})\)$/);
  const weekYearMatch = dateString.match(/^(\d{4}) W(\d{2}) \((\w{3}) (\d{2})\)$/);

  if (weekYearWithMonthDayMatch) {
    const year = parseInt(weekYearWithMonthDayMatch[1], 10);
    const weekNumber = parseInt(weekYearWithMonthDayMatch[2], 10);
    const startOfWeek = DateTime.utc().set({ year }).set({ weekNumber, weekday: 1 }).startOf("day");
    return startOfWeek.toMillis();
  } else if (weekYearMatch) {
    const year = parseInt(weekYearMatch[1], 10);
    const weekNumber = parseInt(weekYearMatch[2], 10);
    const startOfWeek = DateTime.utc().set({ year }).set({ weekNumber, weekday: 1 }).startOf("day");
    return startOfWeek.toMillis();
  } else if (dateString.length === 16) {
    return DateTime.fromFormat(dateString, "yyyy-MM-dd-HH:mm", { zone: "utc" }).toMillis();
  } else if (dateString.length === 10) {
    return DateTime.fromFormat(dateString, "yyyy-MM-dd", { zone: "utc" }).toMillis();
  } else if (dateString.length === 7) {
    return DateTime.fromFormat(dateString, "yyyy-MM", { zone: "utc" }).toMillis();
  } else {
    throw new Error("Unsupported date format");
  }
}

export function hoursElapsedSinceStartOfDay() {
  const now = new Date();
  return now.getHours();
}

export function daysElapsedSinceStartOfWeek() {
  const now = new Date();
  const dayOfWeek = now.getUTCDay();
  return dayOfWeek === 0 ? 7 : dayOfWeek;
}

export function daysElapsedSinceStartOfMonth() {
  const today = DateTime.local();

  const startOfMonth = today.startOf("month");

  const daysUntilToday = today.diff(startOfMonth, "days").days + 1;

  return Math.floor(daysUntilToday);
}

export function weeksElapsedSinceStartOfQuarter() {
  const now = DateTime.utc();
  const quarterStartMonth = Math.floor((now.month - 1) / 3) * 3 + 1;
  const quarterStart = DateTime.utc(now.year, quarterStartMonth, 1);
  const elapsedWeeks = Math.floor(now.diff(quarterStart, "weeks").weeks);
  return elapsedWeeks;
}

export function monthsElapsedSinceStartOfYear() {
  const now = new Date();
  const currentMonth = now.getMonth() + 1;
  return currentMonth;
}

export function filterByCurrentDay(data) {
  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth();
  const currentDate = now.getUTCDate();

  return data.filter((item) => {
    const itemDate = new Date(item.x);
    return !(
      itemDate.getFullYear() === currentYear &&
      itemDate.getMonth() === currentMonth &&
      itemDate.getUTCDate() === currentDate
    );
  });
}

export function filterByCurrentWeek(data) {
  const now = DateTime.utc();

  const startOfWeek = now.startOf("week");
  const endOfWeek = startOfWeek.plus({ days: 6 }).endOf("day");

  return data.filter((item) => item.x >= startOfWeek && item.x <= endOfWeek);
}

export function filterByCurrentMonth(data) {
  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth();

  return data.filter((item) => {
    const itemDate = new Date(item.x);
    return itemDate.getFullYear() === currentYear && itemDate.getMonth() === currentMonth;
  });
}

export function filterByCurrentQuarter(data) {
  const { start, end } = getStartAndEndOfQuarterUTC();

  return data.filter((item) => item.x >= start && item.x <= end);
}

export function filterByCurrentYear(data) {
  const currentYear = DateTime.utc().year;

  return data.filter((item) => item.x.year === currentYear);
}

export function getStartAndEndOfQuarterUTC() {
  const now = DateTime.utc();
  const currentMonth = now.month;

  const startMonth = Math.floor((currentMonth - 1) / 3) * 3 + 1;

  const start = DateTime.utc(now.year, startMonth, 1).startOf("day");

  const end = start.plus({ months: 3 }).minus({ days: 1 }).endOf("day");

  return { start, end };
}

export function getStartAndEndOfMonthUTC() {
  const now = DateTime.utc();

  const start = now.startOf("month");

  const end = now.endOf("month");

  return { start, end };
}

export function getStartAndEndOfYearUTC() {
  const start = DateTime.utc().startOf("year");
  const end = DateTime.utc().endOf("year");
  return { start, end };
}

export const getBudgetConfiguration = (
  budgetConfig: BudgetConfig
): {
  type: BudgetConfigurations;
  dynamic?: BudgetDynamicConfigurations;
} => {
  const { allowGrowth, usePrevSpend, rollover } = budgetConfig;
  if (rollover?.enabled) {
    return {
      type: BudgetConfigurations.DYNAMIC,
      dynamic: BudgetDynamicConfigurations.NEXT_PERIOD_ROLLOVER,
    };
  } else if (allowGrowth && usePrevSpend) {
    return {
      type: BudgetConfigurations.DYNAMIC,
      dynamic: BudgetDynamicConfigurations.LAST_PERIOD_AND_PERCENTAGE_GROWTH,
    };
  } else if (allowGrowth) {
    return { type: BudgetConfigurations.DYNAMIC, dynamic: BudgetDynamicConfigurations.PERCENTAGE_GROWTH };
  } else if (usePrevSpend) {
    return { type: BudgetConfigurations.DYNAMIC, dynamic: BudgetDynamicConfigurations.LAST_PERIOD };
  } else {
    return { type: BudgetConfigurations.SINGLE_PERIOD };
  }
};

export const formatBudgetValue = (value: number, currency: CurrencyCode): string =>
  formatValueWithCurrency(value, 2, currency, false);

export const isForecastedDateInBudgetRange = (
  forecastedDate: DateTime,
  startPeriod: DateTime,
  endPeriod: DateTime
): boolean => {
  const forecastedDateToUTC = forecastedDate.toUTC();
  return forecastedDateToUTC >= startPeriod && forecastedDateToUTC <= endPeriod;
};

export const getBudgetAmountToDate = (
  budgetAmount: number,
  type: BudgetTypes,
  timeInterval: TimeInterval | undefined,
  startPeriod?: Timestamp,
  endPeriod?: Timestamp
): number => {
  let currentIntervalValue: number;
  let intervalValue: number;

  if (!timeInterval && type === BudgetTypes.FIXED) {
    timeInterval = TimeInterval.MONTH;
    currentIntervalValue = buildRange(
      startPeriod ? DateTime.fromJSDate(startPeriod.toDate()) : DateTime.utc(),
      endPeriod ? DateTime.fromJSDate(endPeriod.toDate()) : DateTime.utc(),
      timeInterval,
      type
    ).length;
    intervalValue = currentIntervalValue;
  } else {
    currentIntervalValue = previewAmountByInterval[timeInterval ?? TimeInterval.MONTH];
    intervalValue = fullPreviewAmountByInterval[timeInterval ?? TimeInterval.MONTH];
  }

  const currentAmount = (budgetAmount / intervalValue) * currentIntervalValue;
  return currentAmount;
};

export const getRolloverLimit = (timeInterval: TimeInterval): number => {
  switch (timeInterval) {
    case TimeInterval.DAY:
      return 90;
    case TimeInterval.WEEK:
      return 52;
    case TimeInterval.MONTH:
      return 12;
    case TimeInterval.QUARTER:
      return 8;
    case TimeInterval.YEAR:
      return 5;
    default:
      throw new Error("Invalid time interval");
  }
};
